"use strict";

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').slideToggle('slow', function () {
            $.cookie('giodoCookies', '1', {
                expires: 365
            });
        });
    });
};

var slider = function() {

    var activeClass = 'slick-active',
        ariaAttribute = 'aria-hidden';
    $( '.top-carousel' )
        .on( 'init', function() {
            $( '.slick-dots li:first-of-type' ).addClass( activeClass ).attr( ariaAttribute, false );
        } )
        .on( 'afterChange', function( event, slick, currentSlide ) {
            $.each(slick.$dots, (i, el) => {
                $(el).find('li').eq(currentSlide).addClass('slick-active').find('button');
            })
        });


    $('.top-carousel').slick({
        prevArrow: false,
        nextArrow: false,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: '.dots-wrapper',
    });
}

var menu = function() {

    $('.hamburger').on('click', function(){
        if($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
            $('.main-menu').removeClass('mobile-menu');
        } else {
            $(this).addClass('is-active');
            $('.main-menu').addClass('mobile-menu');
        }
    })

    $('.main-menu a').on('click', function(){
        $('.main-menu.mobile-menu').removeClass('mobile-menu');
        $('.hamburger').removeClass('is-active');
    });

    var scrollOffset;
    if ($(window).width() < 1200) {
        scrollOffset = -90;
    } else {
        scrollOffset = -160;
    }

    $('#back').on('click', function(){
        history.back();
    })

    $.scrollIt({
        topOffset: scrollOffset,
    });
}

var scrollMagic = function() {

    // set main controler
    var controller = new ScrollMagic.Controller();

    $('#app > section').each(function(){

        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: '0.6',
            offset: 0,
            reverse: true
        })
            .setClassToggle(this,'start-animation')
            .addTo(controller);
    });
};

$(function () {
    cookies();
    menu();
    slider();
    scrollMagic();
});





